@charset "UTF-8";

html, body {
  height: auto;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: none;
}

em img {
  max-width: 100%;
  margin-left: 0;
}

body {
          box-sizing: border-box;
     -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.wrapper {
    max-width: -webkit-calc(800px - (#{$horizontal-spacing-unit} * 2));
    max-width:         calc(800px - (#{$horizontal-spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $horizontal-spacing-unit;
    padding-left: $horizontal-spacing-unit;
    @extend .clearfix;
}

.center-image
{
    text-align: center;
}

.image-caption {
  text-align: center;
  font-size: 10px;
  color: #808080;
}