figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}


.blankbox {
    background: $theme-color;
}
.img_row {
    /*height: $img-height;*/
    width: 100%;
    overflow: hidden;
    box-sizing:border-box;
}
.col {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing:border-box;
    /*padding: $img-spacing;*/
}
.one-forth {
    width: 25%;
}
.one {
    width: 33%;
    margin-left: 1%;
    margin-top: -1%;
}
.two {
    width: 66.66%;
}
.three {
    width: 100%;
}
.caption {
    height: 100%;
    color: $caption-color;
    text-align: center;
    vertical-align: middle;
    font-size: $caption-font-size;
}

.content {
    text-align: justify;
}

.namecard {
    width: 100%;
}

.img-td {
    width: $pub-img-width;
    align-content: center;
    padding: 0 2% 0 0;
    border: 0pt;
    vertical-align: top;
}

.txt-td {
    width: $pub-txt-width;
    text-align: left;
    padding: 0 0;
    border: 0pt;
    vertical-align: top;
}

.logo-img-td {
    width: $logo-img-width;
    align-content: center;
    border: 0pt;
    vertical-align: center;
    padding: 0 10px;
}

.logo-txt-td {
    width: $logo-txt-width;
    text-align: left;
    border: 0pt;
    vertical-align: center;
}

