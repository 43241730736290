body,
button {
  margin: 0 5%;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}

img {
  max-width: 100%;
}

svg {
  max-height: 100%;
}
